<template>
	<div class="aboutUs">
		<h1>品牌简介</h1>
		<p class="brief" v-for="(item,index) in data.brief" :key="index">{{item}} </p>
		<h2>核心产品</h2>
        <p class="msg">
           {{data.task}}
        </p>
        <h2>产品介绍 </h2>
        <p class="msg">
           {{data.companyBrief}}
        </p>
          <h2>产品特色 </h2>
        <p class="msg">
           {{data.advantage}}
        </p>
        <h2>产品科技 </h2>
        <p class="msg">
           {{data.technology}}
        </p>
        <h2>教学顾问</h2>
		<p class="msg">
			<ul v-for="(item, idx) in data.notes" :key="idx">
				<li> {{item}}</li>
			</ul>
		</p>
         <h2>线下活动</h2>
        <p class="msg">
            <ul>
                <li v-for="(item,index) in data.invest" :key="index" class="activity" >{{item.name}}
                    <div class="links" @click="goto(item.url)">查看活动详情》</div>
                </li>

            </ul>

        </p>
	</div>
</template>
<script>
export default {
	name: 'aboutUs',
	data () {
		return {
            data:{}
		}
	},
    created(){
        this.getData()
    },
    methods:{
        getData(){
             this.axios.get('https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/brand.json').then(res=>{
           this.data=res.data
         })
        },
        goto(url){
           window.open(url,"_blank")
        }
    }
}
</script>
<style lang="scss" scoped>
.aboutUs{
    text-align: left;
    
    h1{
        font-size: 50px;
        color:#444144;
        font-weight: bold;
    }
    .brief{
        font-size: 18px;
        color: #444144;
        line-height: 34px;
        text-indent:2em;
        margin: 28px 0 30px;
    }
    h2{
        color: #FF7041;
        font-size: 32px;
        font-weight: bold;
    }
    .msg{
        font-size: 18px;
        color: #444144;
        line-height: 30px;
        margin: 17px 0 38px;
    }
    ul li{
        text-indent: 20px;
    }
    .msg li span{
        font-weight: bold;
        margin-right: 10px;
    }
    .msg p{
            text-indent: 20px;
    }
    .links{
        color:#3385ff ;
        &:hover{
            cursor: pointer;
        }
    }
    .activity{
        margin-bottom: 20px;
    }
}
</style>
